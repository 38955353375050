import React from "react";

function Welcome() {
  return (
    <div>
      <h1>Welcome to My React App!</h1>
      <p>This is the home page.</p>
    </div>
  );
}

export default Welcome;
